import { type IconName } from "@/app/_components/icon";

export interface MenuItem {
  name: string;
  href?: string;
  children?: MenuItem[];
  icon?: IconName;
}

export const menuItems: MenuItem[] = [
  {
    name: "Solarrechner",
    href: "/solarrechner/",
  },
  {
    name: "Wärmepumpen-Rechner",
    href: "/waermepumpenrechner/",
  },
  {
    name: "Ratgeber",
    children: [
      {
        name: "PV Anlage",
        href: "/pv-anlage/",
        icon: "solar",
        children: [
          {
            name: "Wärmepumpe",
            href: "https://www.selfmade-energy.com/waermepumpe/waermepumpe-berechnen/",
          },
          {
            name: "Batteriespeicher",
            href: "https://solarwissen.selfmade-energy.com/wann-lohnt-es-sich-eine-pv-anlage-mit-einem-batteriespeicher-zu-kombinieren/",
          },
          {
            name: "Balkonkraftwerk",
            href: "https://solarwissen.selfmade-energy.com/balkonkraftwerke-2024-aenderungen-und-moeglichkeiten/",
          },
        ],
      },
      {
        name: "Finanzierung",
        href: "https://solarwissen.selfmade-energy.com/photovoltaik-anlage-kaufen/",
        icon: "grant",
        children: [
          {
            name: "Mieten oder kaufen",
            href: "https://solarwissen.selfmade-energy.com/photovoltaikanlage-mieten-oder-kaufen/",
          },

          {
            name: "Lohnt sich Photovoltaik",
            href: "https://solarwissen.selfmade-energy.com/wie-schnell-rechnet-sich-eine-photovoltaikanlage/",
          },

          {
            name: "Förderung",
            href: "https://solarwissen.selfmade-energy.com/foerderung/",
          },
        ],
      },
      {
        name: "Stromverbrauch",
        href: "https://solarwissen.selfmade-energy.com/strom-sparen-effektive-strategien-fuer-ihren-energiehaushalt/",
        icon: "electric-appliances",
        children: [
          {
            name: "Kühlschrank",
            href: "https://solarwissen.selfmade-energy.com/wie-viel-strom-verbraucht-ein-kuehlschrank/",
          },

          {
            name: "Waschmaschine",
            href: "https://solarwissen.selfmade-energy.com/wie-viel-strom-verbraucht-eine-durchschnittliche-waschmaschine/",
          },

          {
            name: "Fernseher",
            href: "https://solarwissen.selfmade-energy.com/wie-viel-strom-verbraucht-ein-fernseher/ ",
          },
        ],
      },
      {
        name: "Wissen",
        href: "https://solarwissen.selfmade-energy.com/solar-grundlagen/",
        icon: "knowledge",
        children: [
          { name: "Magazin", href: "https://solarwissen.selfmade-energy.com/" },
          {
            name: "Lexikon",
            href: "https://solarwissen.selfmade-energy.com/solarlexikon/",
          },
          {
            name: "Branchenbuch",
            href: "https://verzeichnis.selfmade-energy.com/ ",
          },
        ],
      },
      {
        name: "Trends & Statistiken",
        href: "https://solarwissen.selfmade-energy.com/solar-staedte-ranking/",
        icon: "stats",
        children: [
          {
            name: "Solaratlas",
            href: "https://solarwissen.selfmade-energy.com/selfmade-energy-solaratlas-q1-2024/",
          },

          {
            name: "Preisentwicklung",
            href: "https://solarwissen.selfmade-energy.com/dynamische-preisentwicklung-fuer-solarmodule/",
          },

          {
            name: "Direktvermarktung",
            href: "https://solarwissen.selfmade-energy.com/direktvermarktung-mit-solarstrom-mehr-verdienen-als-ueber-die-einspeiseverguetung/",
          },
        ],
      },
    ],
  },
  {
    name: "Über uns",
    href: "/ueber-uns/",
  },
];
