import { usePathname } from "next/navigation";
import { type MenuItem, menuItems } from "./data";

type UseActiveMenuItem = () => MenuItem | undefined;

export const useActiveMenuItem: UseActiveMenuItem = () => {
  const currentPathname = usePathname();

  return menuItems.find(
    item =>
      item.href &&
      currentPathname?.substring(0, item.href.length) === item.href,
  );
};
