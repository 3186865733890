"use client";

import { useMediaQuery } from "@/app/_hooks/useMediaQuery";
import { type IconName } from "@/app/_components/icon";

import MobileMenu from "./mobilemenu";
import DesktopMenu from "./desktopmenu";
import { useActiveMenuItem } from "./hooks";
import MenuItem from "./item";
import MenuSubItem from "./subitem";
import MenuSubSubItem from "./subsubitem";
import { menuItems } from "./data";

const Menu: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const MenuComponent = isMobile ? MobileMenu : DesktopMenu;
  const activeItem = useActiveMenuItem();

  return (
    <MenuComponent>
      {menuItems.map(item =>
        item.children ? (
          <MenuItem
            key={item.name}
            label={item.name}
            isActive={activeItem?.name === item.name}
          >
            {item.children?.map(subItem => (
              <MenuSubItem
                key={subItem.name}
                href={subItem.href as string}
                label={subItem.name}
                icon={subItem.icon as IconName}
              >
                {subItem.children?.map(subSubItem => (
                  <MenuSubSubItem
                    key={subSubItem.name}
                    href={subSubItem.href as string}
                    label={subSubItem.name}
                  />
                ))}
              </MenuSubItem>
            ))}
          </MenuItem>
        ) : (
          <MenuItem
            key={item.name}
            href={item.href as string}
            label={item.name}
            isActive={activeItem?.name === item.name}
          />
        ),
      )}
    </MenuComponent>
  );
};

export default Menu;
