import classNames from "classnames";
import Link from "next/link";

import Icon from "@/app/_components/icon";

type LinkProps = React.ComponentProps<typeof Link>;

const ArrowLink: React.FC<LinkProps> = ({ className, children, ...props }) => (
  <Link {...props} className={classNames(className, "group/arrowlink")}>
    {children}
    <Icon
      name="arrow-right"
      className="ml-2 h-4 w-4 -translate-x-4 opacity-0 transition group-hover/arrowlink:translate-x-0 group-hover/arrowlink:opacity-100"
    />
  </Link>
);

export default ArrowLink;
