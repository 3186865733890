import React from "react";
import ArrowLink from "./arrowlink";

interface MenuSubSubItemProps {
  href: string;
  label: string;
}

export default function MenuSubSubItem({ href, label }: MenuSubSubItemProps) {
  return (
    <div className="flex items-center gap-4">
      <ArrowLink
        href={href}
        className="flex h-12 items-center pl-12 pr-4 font-normal hover:text-primary-600 focus:outline-none tablet:pl-8 laptop:pl-0"
      >
        {label}
      </ArrowLink>
    </div>
  );
}
