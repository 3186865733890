"use client";

import { twMerge } from "tailwind-merge";

import { useActiveMenuItem } from "@/app/_components/menu";
import HeaderTitle from "./title";

const ActivePageTitle = ({ className = undefined }: { className?: string }) => {
  const activeItem = useActiveMenuItem();
  return activeItem ? (
    <div
      className={twMerge(
        "flex grow items-baseline gap-2 laptop:hidden",
        className,
      )}
    >
      <HeaderTitle>{activeItem.name}</HeaderTitle>
    </div>
  ) : null;
};

export default ActivePageTitle;
