import { twMerge } from "tailwind-merge";

const HeaderTitle: React.FC<
  React.PropsWithChildren & { className?: string }
> = ({ children, className }) => (
  <div
    className={twMerge(
      " w-full truncate text-sm font-black text-text tablet:text-base laptop:text-lg",
      className,
    )}
  >
    {children}
  </div>
);

export default HeaderTitle;
